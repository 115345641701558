<template>
  <v-container id="features" fluid>
    <h4 class="text-h4 font-weight-bold primary--text text-center my-6">
      Tune-Up Every Facet of Your Business
    </h4>
    <section class="d-flex flex-row flex-wrap justify-center mb-16">
      <feature-card
        v-for="(card, index) in cards"
        :key="index"
        :card="card"
        class="ma-4"
      />
    </section>
  </v-container>
</template>

<script>
import FeatureCard from "@/components/FeatureCard";

export default {
  components: {
    FeatureCard
  },
  data: function () {
    return {
      cards: [
        {
          title: "Copyright Management",
          desc: "Underlying musical works are different from products created from them and their derivatives, and Chordata makes it easy to see the complex connections at a glance.",
          bullets: ["Auto-generates contracts", "IP data visualization map"]
        },
        {
          title: "Digital Assets Management",
          desc: "Your PDFs, product images, audio files and marketing media such as headshots, and brochures are all stored in the same place as the relevant data for each product with version control.",
          bullets: ["Digital conversion", "IDML templates", "MP3 & PDF tagging"]
        },
        {
          title: "Musical Data Management",
          desc: "Robust data validation for complex musical concepts such as keys and tempi. Handles all the complexities of instrumentation and ensembles.",
          bullets: [
            "Instrumentation and ensembles",
            "Keys, tempi, and ranges",
            "Program notes text editor"
          ]
        },
        {
          title: "Contacts Management",
          desc: "From composers, arrangers and librettists to publishing partners, payees and more, manage them all in one place with everything from their biographies to phone numbers to their musical works and products.",
          bullets: [
            "Fully handles names and aliases",
            "DBA (Doing Business As)",
            "Manages roles (composer/arranger)"
          ]
        },
        {
          title: "Smart Search",
          desc: "Flexible global searching allows you to do text searches on the entire database. A highly specialized advanced search allows the user to create queries with detailed accuracy which can be saved for later use.",
          bullets: ["Quick search", "Advanced search", "Save-and-share queries"]
        },
        {
          title: "Product Data Management",
          desc: "All your products’ musical data, website descriptions, warehouse data, print specs, and sales data in one interface, plus easy access to the files for quick reference.",
          bullets: [
            "Centralized data source",
            "Full text editor for descriptions",
            "Categorization and custom tagging"
          ]
        },
        {
          title: "Royalties",
          desc: "A built-in calculator automatically takes the terms from contracts combined with sales, rental, and licensing revenue to generate royalty reports for each composer.",
          bullets: ["Worry-free reporting", "Handles complex royalties"]
        },
        {
          title: "Integrations",
          desc: "Chordata automatically synchronizes your data between Microsoft NAV, Magento websites, HLMSW, Zinfonia, in©, the UIC, and digital music platforms (Newzik and forScore). Other integrations are possible, please inquire.",
          bullets: [
            "Real-time integrations with ERP",
            "Interface with digital platforms",
            "Automatic updates everywhere"
          ]
        }
      ]
    };
  }
};
</script>
