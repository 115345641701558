<template>
  <v-app-bar app color="white" height="80">
    <v-container
      id="header-items"
      class="d-flex justify-sm-space-between align-center"
    >
      <!-- hidden unless xs or sm -->
      <header-menu :nav="handleNav" />

      <v-img
        :src="require('@/assets/header-logo.svg')"
        max-width="280"
        contain
        @click="handleNav('/')"
        v-scroll-to="'#hero'"
      />

      <v-btn
        class="d-none d-md-block"
        elevation="0"
        color="white"
        v-scroll-to="'#about'"
        @click="handleNav('/#features')"
      >
        Features
      </v-btn>

      <v-btn
        class="d-none d-md-block"
        elevation="0"
        color="white"
        v-scroll-to="'#discover'"
        @click="handleNav('/#discover')"
      >
        Why Chordata?
      </v-btn>
      <appointment-button class="d-none d-sm-block" :nav="handleNav" />
    </v-container>
  </v-app-bar>
</template>

<script>
import AppointmentButton from "@/components/AppointmentButton";
import HeaderMenu from "@/components/HeaderMenu";

export default {
  components: {
    AppointmentButton,
    HeaderMenu
  },
  methods: {
    handleNav(path) {
      if (this.$route.name === "home") {
        return;
      } else {
        this.$router.push({ path });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
#header-items {
  &::v-deep .v-btn {
    text-transform: none;

    .v-btn__content {
      font-size: 1.2rem;
      letter-spacing: 0;
    }
  }

  .v-image {
    cursor: pointer;
  }
}
</style>
