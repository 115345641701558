<template>
  <v-container fluid pa-16 text-center>
    <h1 class="ma-16 pa-16">Page not found...sorry :(</h1>
  </v-container>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
