<template>
  <v-card max-width="370" id="feature-card">
    <v-card-title class="text-h5 font-weight-bold gray--text text--darken-2">
      {{ card.title }}
    </v-card-title>
    <v-card-subtitle id="card-desc"> {{ card.desc }} </v-card-subtitle>
    <v-divider />
    <v-list>
      <v-list-item v-for="(bullet, index) in card.bullets" :key="index">
        <v-list-item-icon class="mr-2 my-1">
          <v-img
            contain
            height="36"
            :src="require('@/assets/bullet-check.svg')"
          />
        </v-list-item-icon>
        <v-list-item-content class="pt-1 pb-2 gray--text text--darken-2">
          {{ bullet }}
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-card>
</template>

<script>
export default {
  props: {
    card: Object
  }
};
</script>

<style lang="scss" scoped>
@import "~vuetify/src/styles/settings/_variables";

@media #{map-get($display-breakpoints, 'md-and-up')} {
  #card-desc {
    height: 145px;
  }
}

#feature-card {
  .v-list-item__content,
  .v-card__subtitle {
    font-size: 1.1rem;
  }
}
</style>