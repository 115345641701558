<template>
  <v-container id="discover" class="surface" px-16 pt-8 pb-16 fluid>
    <v-container text-center>
      <h1 class="chordata">Your Team Will Thank You!</h1>
      <p>
        Click to discover how the tools inside Chordata are changing the way
        deparments function independently and together.
      </p>
    </v-container>
    <v-container fluid align-center>
      <v-row justify="center">
        <Person
          v-for="person in people"
          :key="person"
          :type="person"
          show-button
        />
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
import Person from "@/components/Person";

export default {
  components: {
    Person
  },
  data() {
    return {
      people: [
        "editorial",
        "creative",
        "marketing",
        "licensing",
        "leadership",
        "royalties",
        "sales",
        "rental"
      ]
    };
  }
};
</script>

<style lang="scss">
@import "~vuetify/src/styles/styles.sass";

.chordata {
  color: $color-primary;
}
</style>
