<template>
  <v-sheet id="hero" color="primary" class="py-4">
    <v-img :src="require('@/assets/TopHero/chordata-logo-with-staff.png')" />
    <!-- contain -->
    <section class="mt-6">
      <h4 class="white--text text-h4 font-weight-bold text-center">
        Meet the Future of Music Publishing
      </h4>
      <p id="chordata-subtitle" class="ma-auto white--text text-h5 text-center">
        Purpose built for Music Publishers, Chordata creates the infrastructure
        to turbocharge your team, business, and future.
      </p>
    </section>
    <top-hero-icons></top-hero-icons>
  </v-sheet>
</template>

<script>
import TopHeroIcons from "@/components/TopHeroIcons";
export default {
  components: {
    TopHeroIcons
  }
};
</script>

<style lang="scss" scoped>
#chordata-subtitle {
  max-width: 800px;
}
</style>
