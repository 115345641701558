<template>
  <div>
    <TopHero />
    <sub-hero />
    <feature-cards />
    <Discover />
    <Form />
    <Footer />
  </div>
</template>

<script>
import TopHero from "@sections/TopHero";
import SubHero from "@sections/SubHero";
import FeatureCards from "@sections/FeatureCards";
import Discover from "@sections/Discover";
import Form from "@sections/Form";
import Footer from "@sections/Footer";

export default {
  components: {
    TopHero,
    SubHero,
    FeatureCards,
    Discover,
    Form,
    Footer
  }
};
</script>

<style lang="scss" scoped></style>
