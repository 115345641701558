<template>
  <div d-inline class="less-margin expand">
    <v-img
      :src="personAsset"
      :max-width="width"
      @click="goToDepartment"
    />
    <v-row v-if="showButton" justify="center">
      <v-btn x-large class="primary" min-width="150" @click="goToDepartment">{{
        type
      }}</v-btn>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    width: {
      type: Number,
      default: 240
    },
    type: {
      type: String,
      required: true
    },
    showButton: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      personAsset: require(`@/assets/people/${this.type}.png`)
    };
  },
  methods: {
    goToDepartment() {
      this.$router.push({
        name: "department",
        params: { department: this.type }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.less-margin {
  /* Negative margins to get them closer seems hacky, maybe just edit the assets? Or accept the spacing */
  margin: 0 -2rem;
}

.expand {
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  &:hover {
    transform: scale(1.1);
  }
}
</style>
