<template>
  <v-container fluid class="surface">
    <section id="about" class="d-flex flex-wrap flex-md-nowrap mx-auto pa-8">
      <v-img
        :src="require('@/assets/header-logo.svg')"
        max-width="320"
        class="mx-auto mb-4 mb-md-0"
        contain
      />
      <h5 class="ma-auto ml-4 text-h5">
        Purpose-built by Music Publishers for Music Publishers, Chordata is a
        game-changing, comprehensive cloud-based solution serving all functions
        of music publishing.
      </h5>
    </section>
  </v-container>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
#about {
  max-width: 1360px;
}
</style>
