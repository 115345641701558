import Vue from "vue";
import Router from "vue-router";
import Home from "@pages/Home";
import Department from "@pages/Department";
import NotFound from "@pages/NotFound";

const routes = [
  {
    path: "/",
    name: "home",
    component: Home
  },
  {
    path: "/:department",
    name: "department",
    component: Department
  },
  {
    path: '*',
    component: NotFound
  }
];

Vue.use(Router);

export default new Router({
  mode: "history",
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return {
        selector: to.hash,
        offset: { x: 0, y: -80 }
      };
    } else if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  }
});
