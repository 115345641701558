<template>
  <v-container class="secondary" pa-16 fluid>
    <v-row justify="center">
      <v-img src="@/assets/logo/chordata-logo.png" max-width="220" />
    </v-row>
  </v-container>
</template>

<script>
export default {};
</script>

<style lang="scss">
@import "~vuetify/src/styles/styles.sass";

.strip {
  background-color: $color-secondary;
}
</style>
