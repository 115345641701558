<template>
  <div
    id="department"
    v-if="validDepartment"
    class="d-flex align-center justify-center"
  >
    <v-card class="pa-4 ma-auto pa-sm-12 ma-sm-16">
      <v-row no-gutters class="mx-auto narrow" align="center" justify="center">
        <v-col md="8" cols="12">
          <Quote :content="quote" />
          <ul class="mt-10">
            <li v-for="bullet in bullets" :key="bullet">
              {{ bullet }}
            </li>
          </ul>
        </v-col>
        <v-col md="4">
          <Person :type="department" :width="550" />
        </v-col>
      </v-row>
      <v-row no-gutters class="mx-auto narrow" align="center" justify="center">
        <v-col>
          <department-button :department="department" />
        </v-col>
      </v-row>
    </v-card>
  </div>
  <div v-else>
    <not-found />
  </div>
</template>

<script>
import Person from "@/components/Person";
import Quote from "@/components/Quote";
import DepartmentButton from "@/components/DepartmentButton";
import snippets from "@/data/snippets";
import NotFound from "@pages/NotFound";

export default {
  components: {
    Person,
    Quote,
    DepartmentButton,
    NotFound
  },
  data() {
    return {
      validDepartments: [
        "editorial",
        "creative",
        "marketing",
        "licensing",
        "leadership",
        "royalties",
        "sales",
        "rental"
      ],
      snippets
    };
  },
  computed: {
    validDepartment() /* : bool */ {
      return this.validDepartments.includes(this.department);
    },
    department() {
      return this.$route.params.department;
    },
    quote() {
      return this.snippets[this.department]?.quote;
    },
    bullets() {
      return this.snippets[this.department]?.bullets;
    }
  }
};
</script>

<style lang="scss" scoped>
#department {
  height: 100%;
}

.narrow {
  max-width: 1300px;
}

ul li {
  padding: 5px 0;
}
</style>
