<template>
  <section class="mt-12">
    <v-container id="icon-row" class="d-flex justify-center">
      <template v-for="icon in icons">
        <v-hover :key="icon.key" v-slot="{}" @input="setIconHover(icon.key)">
          <v-img
            :src="icon.image"
            contain
            :class="[
              'mx-md-4',
              'mx-3',
              'icon',
              { 'hero-icon-md': $vuetify.breakpoint.mdAndUp },
              { 'hero-icon': !$vuetify.breakpoint.mdAndUp }
            ]"
          ></v-img>
        </v-hover>
      </template>
    </v-container>
    <v-container class="d-flex justify-center">
      <h5
        id="active-caption"
        class="white--text text-md-h5 text-body-1 text-center"
        v-html="activeCaption"
      ></h5>
    </v-container>
  </section>
</template>

<script>
export default {
  data: function () {
    return {
      icons: {
        mag: {
          key: "mag",
          image: require("@/assets/TopHero/icon-mag.svg"),
          imageDefault: require("@/assets/TopHero/icon-mag.svg"),
          imageHover: require("@/assets/TopHero/icon-mag-white.svg"),
          caption: "<strong>Searchable</strong> - multi-tiered advanced search",
          isHover: false
        },
        stack: {
          key: "stack",
          image: require("@/assets/TopHero/icon-stack.svg"),
          imageDefault: require("@/assets/TopHero/icon-stack.svg"),
          imageHover: require("@/assets/TopHero/icon-stack-white.svg"),
          caption:
            "<strong>Everything in One Place</strong> - centralized data means all your files and info at your fingertips",
          isHover: false
        },
        dollar: {
          key: "dollar",
          image: require("@/assets/TopHero/icon-dollar.svg"),
          imageDefault: require("@/assets/TopHero/icon-dollar.svg"),
          imageHover: require("@/assets/TopHero/icon-dollar-white.svg"),
          caption:
            "<strong>New Sales Opportunities</strong> - integrate digital sales platforms with ease",
          isHover: false
        },
        royalty: {
          key: "royalty",
          image: require("@/assets/TopHero/icon-royalty.svg"),
          imageDefault: require("@/assets/TopHero/icon-royalty.svg"),
          imageHover: require("@/assets/TopHero/icon-royalty-white.svg"),
          caption:
            "<strong>Simplify Royalty Processing</strong> - no more headaches!",
          isHover: false
        },
        time: {
          key: "time",
          image: require("@/assets/TopHero/icon-time.svg"),
          imageDefault: require("@/assets/TopHero/icon-time.svg"),
          imageHover: require("@/assets/TopHero/icon-time-white.svg"),
          caption:
            "<strong>Enhance Productivity</strong> - free up your time to work on more meaningful projects",
          isHover: false
        },
        future: {
          key: "future",
          image: require("@/assets/TopHero/icon-future.svg"),
          imageDefault: require("@/assets/TopHero/icon-future.svg"),
          imageHover: require("@/assets/TopHero/icon-future-white.svg"),
          caption:
            "<strong>Creating Lasting Success</strong> - become futureproof",
          isHover: false
        }
      },
      hover: null
    };
  },
  methods: {
    setIconHover(key) {
      this.icons[key].isHover = !this.icons[key].isHover;
      // binding src to v-img needs to be a value. Otherwise I would do this logic in the templat...
      if (this.icons[key].isHover) {
        this.icons[key].image = this.icons[key].imageHover;
      } else {
        this.icons[key].image = this.icons[key].imageDefault;
      }
    }
  },
  computed: {
    hoveredIcon() {
      let activeIcon;

      for (let icon in this.icons) {
        if (this.icons[icon].isHover) {
          activeIcon = icon;
        }
      }

      return activeIcon;
    },
    activeCaption() {
      return this.hoveredIcon ? this.icons[this.hoveredIcon].caption : "";
    }
  }
};
</script>

<style lang="scss" scoped>
#icon-row {
  cursor: pointer;

  .hero-icon {
    width: 10%;
    max-width: 70px;
  }
  .hero-icon-md {
    width: 70px;
    height: 70px;
    max-width: 70px;
  }
}

#active-caption {
  min-height: 32px;
}
</style>