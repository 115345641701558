<template>
  <v-menu absolute offset-y style="max-width: 600px">
    <template v-slot:activator="{ on, attrs }">
      <v-app-bar-nav-icon
        class="d-sm-block d-md-none"
        v-bind="attrs"
        v-on="on"
      />
    </template>

    <v-list>
      <v-list-item link @click="nav('/#about')" v-scroll-to="'#about'">
        <v-list-item-title>About</v-list-item-title>
      </v-list-item>
      <v-list-item link @click="nav('/#features')" v-scroll-to="'#features'">
        <v-list-item-title>Features</v-list-item-title>
      </v-list-item>
      <v-list-item
        link
        @click="nav('/#appointment')"
        v-scroll-to="'#appointment'"
      >
        <v-list-item-title>Request an Appointment</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  props: {
    nav: {
      type: Function
    }
  }
};
</script>