<template>
  <v-container id="appointment" class="music-bg" fluid pa-10 text-center>
    <div v-if="submitted">
      <h1 class="ma-16 pa-16">Thanks for contacting us! We'll be in touch.</h1>
    </div>
    <div v-else>
      <h1 class="primary--text mb-12">Request an Appointment</h1>
      <v-form
        ref="form"
        v-model="valid"
        @submit.prevent="handleSubmit"
        class="mx-lg-16 px-lg-16"
        :name="formName"
        method="post"
        data-netlify="true"
        data-netlify-honeypot="bot-field"
      >
        <input type="hidden" name="form-name" :value="formName" />
        <v-container>
          <v-row>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="form.firstName"
                outlined
                background-color="surface"
                name="firstName"
                label="First Name"
                required
                :rules="[(v) => !!v || 'First name is required']"
              />
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="form.lastName"
                outlined
                background-color="surface"
                name="lastName"
                label="Last Name"
                required
                :rules="[(v) => !!v || 'Last name is required']"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="form.company"
                outlined
                background-color="surface"
                name="company"
                label="Company Name"
                :rules="[(v) => !!v || 'Company is required']"
              />
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="form.title"
                outlined
                background-color="surface"
                name="title"
                label="Title"
                :rules="[(v) => !!v || 'Title is required']"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="8">
              <v-text-field
                v-model="form.email"
                outlined
                background-color="surface"
                name="email"
                label="Email Address"
                required
                :rules="emailRules"
              />
            </v-col>
            <v-col cols="12" md="4">
              <v-select
                v-model="form.employees"
                :items="employeesOpts"
                outlined
                background-color="surface"
                name="employees"
                label="Number of Employees"
                type="select"
                required
                :rules="[(v) => !!v || 'Number of Employees is required']"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-textarea
                v-model="form.message"
                outlined
                background-color="surface"
                name="message"
                label="Message"
              />
            </v-col>
          </v-row>
          <v-row justify="center" justify-md="end">
            <v-btn class="mr-4" @click="reset"> Clear </v-btn>
            <v-btn class="primary mr-4" type="submit" :disabled="!valid">
              Submit
            </v-btn>
          </v-row>
        </v-container>
      </v-form>
    </div>
  </v-container>
</template>

<script>
import SerializeForm from "@util/SerializeForm";
export default {
  data() {
    return {
      formName: "request-appointment",
      valid: true,
      submitted: false,
      form: {
        firstName: "",
        lastName: "",
        company: "",
        title: "",
        email: "",
        employees: "",
        message: ""
      },
      employeesOpts: ["1 - 4", "5 - 10", "11 - 20", "21 - 50", "50+"],
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid"
      ]
    };
  },
  methods: {
    async handleSubmit() {
      try {
        await fetch("/", {
          method: "POST",
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
          body: SerializeForm({
            "form-name": this.formName,
            ...this.form
          })
        });

        this.setSubmitted(true);
        this.reset();
      } catch (error) {
        console.log("serialize form error", error);
      }
    },
    setSubmitted(submitted) {
      this.submitted = submitted;
    },
    validate() {
      this.$refs.form.validate();
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~vuetify/src/styles/styles.sass";
</style>
