<template>
  <div d-inline>
    <h1 class="quotation font-weight-light">{{ content }}</h1>
  </div>
</template>

<script>
export default {
  props: {
    content: {
      type: String,
      default: ""
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~vuetify/src/styles/styles.sass";

.quotation {
  &:before {
    display: block;
    content: url("~@/assets/quote.svg");
    transform: scale(0.75);
    display: inline-block;

    @media #{map-get($display-breakpoints, 'md-and-down')} {
      display: block;
    }
  }
}
</style>
