import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from "./router";
import ScrollTo from "vue-scrollto";
import VueGtag from "vue-gtag";

Vue.config.productionTip = false;

Vue.use(ScrollTo, {
  offset: -80
});

Vue.use(VueGtag, {
  config: { id: "G-FQ77B4XEXG" }
});

new Vue({
  router,
  vuetify,
  render: (h) => h(App)
}).$mount("#app");
