<template>
  <v-app>
    <app-header />

    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import AppHeader from "@sections/Header";
export default {
  name: "App",
  components: {
    AppHeader
  },

  data: () => ({
    //
  })
};
</script>
