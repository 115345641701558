<template>
  <v-btn
    id="appointment-button"
    elevation="0"
    color="primary"
    v-scroll-to="'#appointment'"
    @click="nav && nav('/#appointment')"
  >
    Request an appointment
  </v-btn>
</template>

<script>
export default {
  props: {
    nav: {
      type: Function || undefined
    }
  }
};
</script>

<style lang="scss" scoped>
#appointment-button.v-btn {
  text-transform: none;

  &::v-deep .v-btn__content {
    font-size: 1.2rem;
    letter-spacing: 0;
  }
}
</style>
