import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#074db2",
        // secondary: "#TBD",
        // accent: "#TBD",
        // error: "#TBD",
        // info: "#TBD",
        // success: "#TBD",
        // warning: "#TBD",

        // ----- Whites and grays -----
        background: "#f1f2f4",
        secondary: "#f2f2f4",
        surface: "#ffffff",
        gray: "#9ea3a8",
        black: "#23282d",

        // ----- Entities -----
        product: "#098041",
        work: "#123ba2",
        contract: "#e97b29",
        human: "#9e1c46",
        asset: "#615595",
        publisher: "#8393aa",
        
        // Not sure if this will work like it does in styles.scss
        /* music: `url("~@/assets/mask.png")` */
      }
    }
  }
});
