<template>
  <v-btn
    large
    block
    class="primary"
    v-scroll-to="'#discover'"
    @click="$router.push({ path: '/#discover' })"
  >
    <v-icon left>mdi-arrow-left-circle</v-icon>
    <v-spacer />
    {{ department }}
    <v-spacer />
  </v-btn>
</template>

<script>
export default {
  props: {
    department: {
      type: String,
      required: true
    }
  }
};
</script>

<style lang="scss" scoped></style>
