import { render, staticRenderFns } from "./FeatureCard.vue?vue&type=template&id=515d43ea&scoped=true&"
import script from "./FeatureCard.vue?vue&type=script&lang=js&"
export * from "./FeatureCard.vue?vue&type=script&lang=js&"
import style0 from "./FeatureCard.vue?vue&type=style&index=0&id=515d43ea&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "515d43ea",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VImg } from 'vuetify/lib/components/VImg';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
installComponents(component, {VCard,VCardSubtitle,VCardTitle,VDivider,VImg,VList,VListItem,VListItemContent,VListItemIcon})
